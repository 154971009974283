import React, { useEffect } from "react";
import Navbar from "../Components/NavbarPages";
import SendEmail from "../Components/SendEmail";
import Footer from "../Components/Footer";
import HubSpotForm from "../Components/HubSpotForm";
// Asegúrate de que la ruta de importación sea correcta

function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar currentPage="contact" />
      <div className="flex justify-center pt-28 flex-col rounded-lg p-8">
        <h1 className="text-5xl sm:text-6xl uppercase font-black text-pink-500 text-center mt-4 sm:mt-8 mb-8">
          ¡Envíanos un mensaje!
        </h1>
        <p className="text-2xl 2xl:text-3xl text-gray-700 text-center w-full sm:w-1/2 2xl:w-1/3 mx-auto mb-6">
          Te gustaría saber más sobre nuestros productos, o tienes alguna duda o
          comentario, no dudes en contactarnos. Nuestros clientes son lo más
          importante para nosotros, por lo que nos encanta escuchar sus
          comentarios y sugerencias.
        </p>
        <h3 className="text-3xl sm:text-4xl uppercase font-black text-pink-300 text-center mt-8 mb-2 sm:w-3/5 mx-auto">
          Ingresa tus datos y te contactaremos lo más pronto posible.
        </h3>
        {/* <SendEmail /> */}
        <div className="p-4 sm:p-12 sm:mx-16 md:mx-36 rounded-md bg-pink-50">
          <HubSpotForm
            portalId="44489707"
            formId="34bb78b4-7afe-415a-b779-842663d47702"
            region="na1"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
